import { Port } from "../models/port.model";

export class LoadPorts {
  public static readonly type = "[Ports] load ports";
  constructor(public filters?: any) {}
}
export class AddPort {
  public static readonly type = "[Ports] add port";
  constructor(
    public payload: Port,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class UpdatePort {
  public static readonly type = "[Ports] update port";
  constructor(
    public payload: Port,
    public callbackSuccess: () => void,
    public callbackError: () => void  ) {}
}

export class RemovePort {
  public static readonly type = "[Ports] remove port";
  constructor(
    public portId: string,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}
